import type { SanityTwoUpCard } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';

import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';

export function TwoUpCard(props: SanityTwoUpCard) {
  return (
    <div className="p-main my-105 m:my-210">
      <div
        className="mx-auto flex max-w-1235 flex-col rounded-15 [box-shadow:inset_0_0_0_1px_theme(colors.charcoal)] m:flex-row m:rounded-25"
        style={{ background: props.contentBackground }}
      >
        <div className="pb-50 pl-30 pr-40 pt-30 m:w-2/5 m:pb-60 m:pl-65 m:pr-45 m:pt-50">
          <Headline value={props.headline} variant={5} className="mb-25" />
          <p className="max-w-[350px]">{props.bodyCopy}</p>
          {props.cta ? (
            <Button
              className="mt-35"
              href={props.cta.href}
              target={props.cta.target}
              variant="secondary"
            >
              {props.cta.title}
            </Button>
          ) : null}
        </div>
        <div
          className={cn(
            'rounded-15 border-1 border-charcoal m:w-3/5 m:rounded-25',
            {
              'p-30 m:p-75': props.hasMediaPadding,
              'm:-order-1': props.desktopLayout === 'imageText',
            },
          )}
          style={{ background: props.mediaBackground ?? undefined }}
        >
          <Media
            media={props.media}
            className="size-full"
            mediaClassName={cn({
              'object-contain': props.hasMediaPadding,
              'object-cover': !props.hasMediaPadding,
            })}
            sizes={['100vw', { m: '60vw', '1285px': '740px' }]}
          />
        </div>
      </div>
    </div>
  );
}
