import gsap from 'gsap';

import type { SanityLinkCardGrid } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';
import { Headline } from '@/components/Headline';
import { Link } from '@/components/Link';
import { Media } from '@/components/Media';

export function LinkCardGrid(props: SanityLinkCardGrid) {
  const wrapBackgroundCx = gsap.utils.wrap([
    'bg-flame-04',
    'bg-gold-04',
    'bg-lush-04',
    'bg-apple-04',
    'bg-sky-04',
    'bg-amethyst-04',
  ]);

  return (
    <div className="p-main">
      <div className="mx-auto max-w-[980px]">
        {props.headline ? (
          <Headline className="mb-40 text-center" value={props.headline} />
        ) : null}
        <div className="grid grid-cols-12 gap-15 l:gap-17">
          {props.cards.map((card, index) => (
            <Link
              key={card._key}
              className="group relative col-span-6 m:col-span-4"
              href={card.link.href}
              target={card.link.target}
            >
              <div className="absolute inset-0 rounded-15 bg-charcoal m:rounded-25"></div>
              <div className="relative isolate size-full overflow-clip rounded-15 border-1 border-grayscale-03 bg-grayscale-01 transition will-change-transform group-hover:-translate-x-5 group-hover:-translate-y-5 group-hover:border-charcoal m:rounded-25">
                <div
                  className={cn(
                    'absolute inset-0 size-full opacity-0 transition will-change-[opacity] group-hover:opacity-100',
                    wrapBackgroundCx(index),
                  )}
                  style={{ background: card.hoverColor ?? undefined }}
                ></div>
                <div className="relative flex h-full min-h-150 w-full flex-col p-15 m:min-h-250 m:p-30">
                  <h4 className="break-words text-18 font-[440] leading-110 -tracking-1 m:text-26">
                    {card.link.title}
                  </h4>
                  <p className="mb-15 mt-5 max-w-[220px] text-12 font-light leading-154 m:mt-15 m:text-14">
                    {card.caption}
                  </p>
                  {card.icon?.url ? (
                    <Media
                      media={card.icon}
                      className="mt-auto size-35 m:size-50"
                      mediaClassName="object-contain"
                      sizes="50px"
                    />
                  ) : null}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
