import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import type { SanityFaqSection } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';

import { BasicText } from './BasicText';
import { Headline } from './Headline';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('border-b', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-start justify-between py-20 text-left text-18 font-[440] leading-124 transition-all m:py-35 m:text-22 m:leading-136 [&[data-state=open]_.icon]:rotate-0',
        className,
      )}
      {...props}
    >
      <div className="max-w-[480px]">{children}</div>
      <div className="grid h-24 w-24 shrink-0 place-items-center rounded-full bg-grayscale-01 m:h-32 m:w-32">
        <div className="absolute h-2 w-12 rounded-full bg-charcoal m:w-18"></div>
        <div className="icon absolute h-2 w-12 -rotate-90 rounded-full bg-charcoal transition-transform m:w-18"></div>
      </div>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn('text-body pb-20 pt-0 m:pb-35', className)}>
      {children}
    </div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export function Faq(props: SanityFaqSection) {
  return (
    <div className="p-main">
      <div className="mx-auto max-w-[980px] justify-between gap-x-50 m:flex">
        <Headline value="FAQ" variant={2} className="mb-30" />
        <Accordion
          type="single"
          collapsible={true}
          className="-mt-20 w-full max-w-[735px] m:-mt-35"
        >
          {props.questions.map((item) => (
            <AccordionItem value={item._key} key={item._key}>
              <AccordionTrigger>{item.question}</AccordionTrigger>
              <AccordionContent>
                <BasicText value={item.answer} />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
