import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import type { SanityRichAccordion } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';

import { BasicText } from './BasicText';
import { Headline } from './Headline';
import { Media } from './Media';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(
      'group relative mt-35 rounded-15 pt-25 transition-all first:!mt-0 data-[state=closed]:mt-10 m:mt-55 m:rounded-25 m:data-[state=closed]:mt-30',
      className,
    )}
    {...props}
  >
    <div className="absolute inset-0 rounded-15 bg-cloud transition-all group-data-[state=closed]:scale-95 group-data-[state=closed]:opacity-0 m:rounded-25"></div>
    <div className="relative z-1">{props.children}</div>
  </AccordionPrimitive.Item>
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-start justify-between px-20 text-left text-22 font-[440] leading-105 transition-all hover:!text-charcoal data-[state=closed]:text-grayscale-04 m:px-30 m:text-34',
        className,
      )}
      {...props}
    >
      <div className="max-w-[480px]">{children}</div>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div
      className={cn(
        'rich-text px-20 pb-30 pt-12 m:px-30 m:pb-45 m:pt-15',
        className,
      )}
    >
      {children}
    </div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export function RichAccordion(props: SanityRichAccordion) {
  return (
    <div
      className="p-main rounded-20 py-50 m:rounded-35 m:py-100"
      style={{ background: props.background }}
    >
      <div className="mx-auto max-w-1235 justify-between gap-x-20 m:flex">
        <div className="mb-30 w-full m:mb-0 m:w-1/2 l:w-2/5">
          <Headline
            value={props.headline}
            overline={props.overline}
            variant={3}
          />
          {props.media ? (
            <Media
              media={props.media}
              className="mt-50 hidden m:block"
              sizes={['100vw', { m: '50vw', l: '40vw', '1285px': '494px' }]}
            />
          ) : null}
        </div>
        <div className="w-full m:w-1/2">
          <Accordion
            type="single"
            collapsible={false}
            defaultValue={props.items[0]._key}
          >
            {props.items.map((item) => (
              <AccordionItem value={item._key} key={item._key}>
                <AccordionTrigger>{item.title}</AccordionTrigger>
                <AccordionContent>
                  <BasicText value={item.content} />
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
          {props.media ? (
            <Media
              media={props.media}
              className="mt-50 block m:hidden"
              sizes="100vw"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
