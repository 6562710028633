import type { SanityPageSection } from '@/lib/sanity/queries/sections';

import { PageSection } from './PageSection';

type Props = {
  sections?: SanityPageSection[];
  sectionIndex?: number | null;
};

export function PageSections({ sections, sectionIndex }: Props) {
  if (!sections || sections.length === 0) return null;

  const sectionIndexWithDefault = sectionIndex ?? 0;
  return (
    <>
      {sections.map((section, index) => {
        return (
          <PageSection
            key={section._key}
            section={section}
            index={sectionIndexWithDefault + index}
          />
        );
      })}
    </>
  );
}
