import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import gsap from 'gsap';
import useSWR from 'swr';

import { fetchRipplingJobs } from '@/lib/rippling';
import type { SanityCareersFeed } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';
import { Headline } from '@/components/Headline';
import { Link } from '@/components/Link';
import { Listbox } from '@/components/Listbox';

const ALL = 'All Departments';

export function CareersFeed(props: SanityCareersFeed) {
  const { data } = useSWR('careersFeed', () => fetchRipplingJobs());

  const departments = Array.from(
    new Set([ALL, ...(data?.map((career) => career.department.label) || [])]),
  );

  const [department, setDepartment] = useState(departments[0]);

  const wrapBackgroundHoverCx = gsap.utils.wrap([
    'group-hover:bg-gold-04',
    'group-hover:bg-lush-04',
    'group-hover:bg-apple-04',
    'group-hover:bg-sky-04',
    'group-hover:bg-amethyst-04',
    'group-hover:bg-flame-04',
  ]);

  const addCount = (department: string) => {
    return `${department} (
                ${
                  department === ALL
                    ? data?.length
                    : data?.filter((d) => d.department.label === department)
                        .length
                }
                )`;
  };

  const options = departments.map((department) => ({
    label: addCount(department),
    value: department,
  }));

  const longestLabel = options.reduce((longest, option) => {
    return option.label.length > longest.length ? option.label : longest;
  }, '');

  return (
    <div className="p-main transition-all">
      <div className="mx-auto max-w-[980px]">
        <Headline value={props.title} variant={5} className="mb-20 m:mb-25" />
        <label className="block m:inline-block">
          {/* Set longest label to be the width of the select to avoid layout shift */}
          <span
            className="w-full opacity-0 pr-40 block h-0 pointer-events-none"
            aria-hidden
          >
            {longestLabel}
          </span>
          <Listbox
            name="Department"
            displayValue={addCount(department)}
            defaultValue={department}
            value={department}
            className="m:w-auto"
            options={options}
            onChange={(val) => setDepartment(val)}
          />
        </label>
        <AnimatePresence mode="wait">
          <motion.ul
            key={department}
            className="-mx-8 mt-25 flex flex-wrap gap-y-15 m:mt-50 m:gap-y-16"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {data
              ?.filter(
                (c) => c.department.label === department || department === ALL,
              )
              ?.map((career, index) => {
                return (
                  <li
                    key={career.uuid}
                    className="block w-full px-8 m:w-1/2 l:w-1/3"
                  >
                    <Link
                      className=" group relative block h-full rounded-15 outline-none transition hover:-translate-x-5 hover:-translate-y-5 focus-visible:ring-1 focus-visible:ring-cipher m:rounded-25"
                      href={career.url}
                      target="_blank"
                    >
                      <div className="absolute inset-0 rounded-15 bg-charcoal transition group-hover:translate-x-5 group-hover:translate-y-5 m:rounded-26"></div>
                      <div
                        className={cn(
                          'relative h-full min-h-130 rounded-15 border-1 border-grayscale-03 bg-grayscale-01 p-30 transition hover:border-charcoal m:rounded-25',
                          wrapBackgroundHoverCx(index),
                        )}
                      >
                        <h3 className="mb-15 text-16 font-[440] leading-120 -tracking-1">
                          {career.name}
                        </h3>
                        <p className="text-14 font-light text-grayscale-06">
                          {career.workLocation.label}
                        </p>
                      </div>
                    </Link>
                  </li>
                );
              })}
          </motion.ul>
        </AnimatePresence>
      </div>
    </div>
  );
}
