import clsx from 'clsx';

import type { SanityHeroNewsletter } from '@/lib/sanity/queries/sections';

import { BasicText } from './BasicText';
import { FormEmailCapture } from './FormEmailCapture';
import { Headline } from './Headline';
import { Media } from './Media';

export function HeroNewsletter(props: SanityHeroNewsletter) {
  return (
    <div
      className={clsx('p-main pb-40 m:pb-100', {
        'mt-[calc(var(--header-height)*-1)] pt-[calc(var(--header-height)+3rem)]':
          props.sectionIndex === 0,
        'pt-30': props.sectionIndex !== 0,
        'rounded-20 m:rounded-35': props.hasRoundCorners,
      })}
      style={{
        background: props.background ?? undefined,
        color: props.foreground ?? undefined,
      }}
    >
      <div className="mx-auto max-w-1235 gap-x-20 m:flex">
        <div className="flex-1">
          <Headline
            value={props.headline}
            variant={2}
            className="mb-10 m:mb-25"
          />
          {props.bodyCopy ? (
            <p className="text-body max-w-[480px]">{props.bodyCopy}</p>
          ) : null}
          <div className="form">
            <FormEmailCapture
              className="mt-35 max-w-[480px]"
              emailInputPlaceholder={props.emailInputPlaceholder}
              submitButtonLabel={props.submitButtonLabel}
              trackingEventName={props.trackingEventName}
              pardotCampaignId={props.pardotCampaignId}
              pardotListIds={props.pardotListIds}
              confirmation={props.confirmation}
              variant="hero"
            />
            <BasicText
              className="text-legal mt-30 max-w-[480px] text-grayscale-05"
              value={props.legalText ?? []}
            />
          </div>
        </div>
        <div className="media mt-30 flex-1 m:mt-0">
          <Media
            media={props.media}
            sizes={['100vw', { m: '50vw', l: '620px' }]}
          />
        </div>
      </div>
    </div>
  );
}
