import { strict } from 'assert';
import { z } from 'zod';

const jobSchema = z.array(
  z.object({
    uuid: z.string(),
    name: z.string(),
    department: z.object({ id: z.string(), label: z.string() }),
    url: z.string(),
    workLocation: z.object({ id: z.string(), label: z.string() }),
  }),
);

export async function fetchRipplingJobs() {
  strict(process.env.NEXT_PUBLIC_RIPPLING_API_URL);

  try {
    const response = await fetch(process.env.NEXT_PUBLIC_RIPPLING_API_URL);

    if (!response.ok) {
      throw new Error('Network response hitting Rippling was not ok');
    }

    const data = await response.json();
    const parsedData = jobSchema.parse(data);

    return parsedData;
  } catch (err) {
    console.error('Error fetching from Rippling API: ', err);
    return null;
  }
}
