import * as React from 'react';

export function RenderSEOSchema({
  schemaData,
}: {
  schemaData: Record<string, any> | Array<Record<string, any>>;
}) {
  if (Array.isArray(schemaData)) {
    return (
      <>
        {schemaData.map((data, index) => (
          <script
            key={index}
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
          />
        ))}
      </>
    );
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
    />
  );
}
