import * as React from 'react';
import { useRouter } from 'next/router';
import type { Product, WithContext } from 'schema-dts';

import { SanityHeroProduct } from '@/lib/sanity/queries/sections';
import { extractSlugFromRouter } from '@/lib/utils';

import { RenderSEOSchema } from './Render';

//TODO: Fetch aggregateRating from G2
export function ProductSchema({
  productTitle,
  bodyCopy,
  headline,
}: SanityHeroProduct) {
  const { query } = useRouter();
  const slug = extractSlugFromRouter(query);

  const url = `https://transcend.io/${slug}`;
  const schemaData: WithContext<Product> = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    url,
    slogan: headline,
    name: productTitle,
    description: bodyCopy ?? headline,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.6',
      ratingCount: '104',
    },
  };

  return <RenderSEOSchema schemaData={schemaData} />;
}
