import { Fragment } from 'react';
// @ts-ignore
import { Marqy } from 'marqy';

import type { SanityTestimonialMarquee } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';

export function TestimonialMarquee(props: SanityTestimonialMarquee) {
  return (
    <Marqy>
      <div className="flex items-center gap-x-15 bg-grayscale-01 py-18 pr-15 text-14 font-[440] leading-100 -tracking-1 m:text-16">
        {props.testimonials.map((testimonial, i) => (
          <Fragment key={testimonial._key}>
            <p>{testimonial.quote}</p>
            <div
              className={cn(
                'h-14 w-14 rounded-full border-1 border-charcoal m:h-16 m:w-16',
                {
                  'bg-amethyst-04': i % 3 === 0,
                  'bg-sky-04': i % 3 === 1,
                  'bg-gold-04': i % 3 === 2,
                },
              )}
            ></div>
          </Fragment>
        ))}
      </div>
    </Marqy>
  );
}
