import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { motion } from 'framer-motion';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { SanityTimeline } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';
import { BasicText } from '@/components/BasicText';
import { Media } from '@/components/Media';
import { VideoEmbed } from '@/components/VideoEmbed';

gsap.registerPlugin(useGSAP, ScrollTrigger);

export function Timeline(props: SanityTimeline) {
  // @ts-ignore
  const containerRef = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline>();
  const timelineBarRef = useRef<HTMLDivElement>(null);

  const mediaSizes = {
    large: '666px',
    medium: '480px',
    small: '435px',
  };

  useGSAP(() => {
    tl.current = gsap
      .timeline() //
      .fromTo(
        timelineBarRef.current,
        { scaleY: 0 },
        {
          scaleY: 1,
          ease: 'none',
          immediateRender: true,
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top 50%',
            end: 'bottom 50%',
            scrub: true,
            invalidateOnRefresh: true,
          },
        },
      );
  });

  return (
    <div ref={containerRef} className="p-main relative pt-120 m:pt-180">
      <div className="absolute left-15 top-0 -ml-1 h-full w-2 bg-cipher-01 m:left-1/2">
        <div
          ref={timelineBarRef}
          className="absolute top-0 h-full w-2 origin-top bg-cipher-03"
        ></div>
        <div className="absolute top-0 h-100 w-2 bg-gradient-to-b from-cloud to-cipher-03"></div>
        <div className="absolute bottom-0 h-25 w-2 bg-cloud"></div>
      </div>
      {props.sections.map((section) => {
        if (section._type === 'timelineEvent') {
          return (
            <div
              key={section._key}
              className="mx-auto mb-75 flex w-full max-w-1235 flex-col gap-y-20"
            >
              {section.media && (
                <ScrollReveal
                  className={cn('relative w-full m:w-1/2', {
                    'order-last': section.mediaOrder === 'after',
                    'ml-auto pl-25': section.mediaPosition === 'right',
                    'pl-25 m:pl-0 m:pr-25': section.mediaPosition === 'left',
                    '-mx-7 m:mx-0': section.mediaSize === 'large',
                  })}
                >
                  <div
                    className={cn({
                      'w-full max-w-666': section.mediaSize === 'large',
                      'w-full max-w-480': section.mediaSize === 'medium',
                      'w-full max-w-435': section.mediaSize === 'small',
                      'ml-auto': section.mediaPosition === 'right',
                    })}
                  >
                    <Media
                      media={section.media}
                      className="size-full rounded-15 m:rounded-25"
                      sizes={[
                        '100vw',
                        {
                          [mediaSizes[section.mediaSize]]:
                            mediaSizes[section.mediaSize],
                        },
                      ]}
                    />
                  </div>
                </ScrollReveal>
              )}
              <div className="relative mx-auto w-full max-w-1235 pl-30 m:static m:flex m:pl-0">
                <div className="m:relative m:w-1/2">
                  {section.companyHistory &&
                    section.companyHistory.length > 0 && (
                      <>
                        <ScrollReveal className="flex gap-x-25">
                          <h3 className="mb-10 text-50 font-black italic leading-100 text-cipher m:text-72">
                            {section.year}
                          </h3>
                          <div className="mt-40 hidden h-2 w-full bg-cipher m:block"></div>
                        </ScrollReveal>
                        <ScrollReveal
                          className="w-full max-w-450 shrink-0"
                          delay={0.3}
                        >
                          <BasicText
                            className="rich-text text-pretty"
                            value={section.companyHistory}
                          />
                        </ScrollReveal>
                      </>
                    )}
                </div>
                <div className="m:relative m:w-1/2">
                  <ScrollReveal className="absolute left-0 top-19 -ml-6 size-12 rounded-full bg-cipher m:top-33 m:-ml-8 m:size-16"></ScrollReveal>
                  {section.industryContext &&
                    section.industryContext.length > 0 && (
                      <div className="flex gap-x-25">
                        <ScrollReveal className="mt-40 hidden h-2 w-full bg-cipher m:block"></ScrollReveal>
                        <div className="w-full max-w-450 shrink-0 m:ml-auto">
                          <ScrollReveal className="mb-10">
                            <h3
                              className={cn(
                                'text-[5rem] font-black italic leading-100 text-cipher m:text-[7.2rem] m:text-grayscale-03',
                                {
                                  'hidden m:block':
                                    section.companyHistory &&
                                    section.companyHistory.length > 0,
                                },
                              )}
                            >
                              {section.year}
                            </h3>
                          </ScrollReveal>
                          <ScrollReveal delay={0.3}>
                            <BasicText
                              className="rich-text text-pretty"
                              value={section.industryContext}
                            />
                          </ScrollReveal>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          );
        } else if (section._type === 'timelineTestimonial') {
          return (
            <ScrollReveal
              key={section._key}
              className="relative -mx-7 mb-75 pb-4 pr-4 m:mx-auto m:max-w-496 m:pb-15 m:pr-15"
            >
              <div className="absolute bottom-0 left-4 right-0 top-14 rounded-15 border-1 border-charcoal bg-cipher m:left-15 m:top-15 m:rounded-25"></div>
              <div className="relative rounded-15 border-1 border-charcoal bg-grayscale-01 py-30 pl-35 pr-25 m:rounded-25 m:pl-50 l:py-45 l:pr-65">
                <div className="mb-30 text-22 font-[440] leading-116 -tracking-2">
                  “{section.quote}”
                </div>
                <div className="flex items-center justify-start gap-x-25">
                  {section?.image ? (
                    <Media
                      media={section.image}
                      className="h-15 grow-0"
                      mediaClassName="object-contain"
                      sizes={`${Math.round((15 * section.image.width) / section.image.height)}px`}
                    />
                  ) : null}
                  <div className="space-y-3">
                    <p className="text-14 font-[440] leading-116">
                      {section.name}
                    </p>
                    {section?.role ? (
                      <p className="text-12 font-[440] leading-116 text-grayscale-05">
                        {section.role}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </ScrollReveal>
          );
        } else if (section._type === 'videoEmbed') {
          return (
            <ScrollReveal key={section._key}>
              <VideoEmbed {...section} className="mb-150 mt-100" />
            </ScrollReveal>
          );
        } else if (section._type === 'timelineTextBox') {
          return (
            <ScrollReveal
              key={section._key}
              className="relative -mx-7 mb-75 pb-4 pr-4 m:mx-auto m:max-w-496 m:pb-15 m:pr-15"
            >
              <div className="absolute bottom-0 left-4 right-0 top-4 rounded-15 border-1 border-charcoal bg-cipher m:left-15 m:top-15 m:rounded-25"></div>
              <div className="relative rounded-15 border-1 border-charcoal bg-grayscale-01 px-35 py-30 m:rounded-25 m:px-50 m:py-45">
                <h3 className="mb-15 text-22 font-[440] leading-116 -tracking-2">
                  {section.headline}
                </h3>
                <BasicText
                  className="rich-text text-pretty"
                  value={section.content}
                />
              </div>
            </ScrollReveal>
          );
        }
      })}
    </div>
  );
}

function ScrollReveal({
  className,
  children,
  delay = 0.2,
}: {
  className?: string;
  children?: React.ReactNode;
  delay?: number;
}) {
  return (
    <motion.div
      className={className}
      initial={{
        opacity: 0,
        y: 20,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        delay,
        duration: 1,
        ease: [0.22, 1, 0.36, 1],
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
}
