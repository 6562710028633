// @ts-expect-error TODO: - Add styles within marqy package and fix types
import { Marqy } from 'marqy';

import type { SanityLogoMarquee } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';

import { Overline } from './Overline';

export function LogoMarquee(props: SanityLogoMarquee) {
  return (
    <div
      className={cn({ 'py-50': props.background })}
      style={{
        background: props.background ?? undefined,
        color: props.foreground ?? undefined,
      }}
    >
      {props.overline ? (
        <Overline as="h2" className="mb-20 text-center m:mb-36">
          {props.overline}
        </Overline>
      ) : null}
      <div className="relative">
        <Marqy>
          <div className="flex items-center gap-x-[var(--gap-x)] pr-[var(--gap-x)] [--gap-x:36px] m:[--gap-x:72px]">
            {props.logos.map((logo) => (
              <div
                key={logo._key}
                style={{
                  maskSize: 'contain',
                  WebkitMaskRepeat: 'no-repeat',
                  maskRepeat: 'no-repeat',
                  maskPosition: 'center',
                  WebkitMaskImage: `url(${logo.url})`,
                  maskImage: `url(${logo.url})`,
                  background: props.foreground ?? undefined,
                  width: logo.logoWidth,
                  height: logo.height * (logo.logoWidth / logo.width),
                }}
              />
            ))}
          </div>
        </Marqy>
        <div
          className="absolute inset-y-0 left-0 w-[10%]"
          style={{
            background: `linear-gradient(to right, ${props.background}, ${props.background}00)`,
          }}
        ></div>
        <div
          className="absolute inset-y-0 right-0 w-[10%]"
          style={{
            background: `linear-gradient(to left, ${props.background}, ${props.background}00)`,
          }}
        ></div>
      </div>
    </div>
  );
}
