import type { CSSProperties } from 'react';
// @ts-ignore
import { Marqy } from 'marqy';

import type { SanityPhotoMarquee } from '@/lib/sanity/queries/sections';

import { BasicText } from './BasicText';
import { Media } from './Media';

export function PhotoMarquee(props: SanityPhotoMarquee) {
  return (
    <Marqy>
      <div className="flex items-start gap-x-20 pr-20">
        {props.photos.map((photo) => {
          return (
            <div
              key={photo._key}
              style={{ '--width': `${photo.imageWidth}px` } as CSSProperties}
              className="w-[calc(var(--width)*0.65)] space-y-20 m:w-[var(--width)]"
            >
              <div className="overflow-clip rounded-25">
                <Media
                  media={photo.image}
                  sizes={[
                    `${Math.round(photo.imageWidth * 0.65)}px`,
                    { m: `${photo.imageWidth}px` },
                  ]}
                  placeholder={true}
                />
              </div>
              {photo.caption && (
                <BasicText
                  className="font-azeret text-12 uppercase leading-150 tracking-3"
                  value={photo.caption}
                />
              )}
            </div>
          );
        })}
      </div>
    </Marqy>
  );
}
