import type { SanityTwoUp } from '@/lib/sanity/queries/sections';
import { cn } from '@/lib/utils';
import { Button } from '@/components/Button';
import { Headline } from '@/components/Headline';
import { Media } from '@/components/Media';

import { VideoEmbed } from './VideoEmbed';

export function TwoUp(props: SanityTwoUp) {
  return (
    <div
      className={cn('p-main', {
        'rounded-20 py-60 m:rounded-35 m:py-100': props.background,
        'my-100': !props.background,
      })}
      style={{ background: props.background ?? undefined }}
    >
      <div className="mx-auto grid max-w-1024 grid-cols-8 items-center gap-x-20">
        <div
          className={cn(
            'two-up-content col-span-full m:col-span-4 m:row-start-1 l:col-span-3',
            {
              'm:col-start-1': props.desktopLayout === 'textImage',
              'm:col-start-5 l:col-start-6':
                props.desktopLayout === 'imageText',
              'row-start-2 mt-30 m:mt-0': props.mobileLayout === 'imageText',
              'mb-30 m:mb-0': props.mobileLayout === 'textImage',
            },
          )}
        >
          {props.useLogoAsOverline &&
          props.overlineLogo?.url &&
          props.overlineLogoWidth ? (
            <Media
              media={props.overlineLogo}
              style={{ width: props.overlineLogoWidth }}
              className="mb-12 overflow-clip m:mb-20"
              sizes={`${props.overlineLogoWidth}px`}
            />
          ) : null}
          <Headline
            value={props.headline}
            overline={props.useLogoAsOverline ? undefined : props.overline}
            variant={5}
            className="mb-10 m:mb-25"
          />
          <p className="text-body">{props.bodyCopy}</p>
          {props.cta.href ? (
            <Button {...props.cta} className="mt-25">
              {props.cta.title}
            </Button>
          ) : null}
        </div>
        <div
          className={cn(
            'two-up-media col-span-full m:col-span-4 m:row-start-1 l:col-span-5',
            {
              'm:col-start-1 m:pr-25 l:pr-60':
                props.desktopLayout === 'imageText',
              'm:col-start-5 m:pl-35 l:col-start-4 l:pl-60':
                props.desktopLayout === 'textImage',
            },
          )}
        >
          {props.media._type === 'videoEmbed' ? (
            <VideoEmbed {...props.media} className="my-0" />
          ) : (
            <Media
              media={props.media}
              sizes={['100vw', { m: '50vw', l: '545px' }]}
            />
          )}
        </div>
        {props.details && props.details?.length > 0 ? (
          <div className="col-span-full mt-50 flex flex-col gap-30 m:flex-row l:mt-70">
            {props.details.map((detail, i) => (
              <div
                key={detail._key}
                className="two-up-detail flex-1"
                style={{ transitionDelay: `${0.4 + i * 0.1}s` }}
              >
                <div className="text-body max-w-[375px] text-pretty">
                  <h3 className="mb-10 !font-[440] !text-charcoal">
                    {detail.title}
                  </h3>
                  <p>{detail.caption}</p>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
