import clsx from 'clsx';

import type { SanityHeroProduct } from '@/lib/sanity/queries/sections';

import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';
import { ProductSchema } from './SeoSchema/ProductSchema';

export function HeroProduct(props: SanityHeroProduct) {
  return (
    <>
      <ProductSchema {...props} />
      <div
        className={clsx(
          'p-main rounded-b-20 bg-grayscale-01 pb-40 text-center m:rounded-b-35 m:pb-100',
          {
            'mt-[calc(var(--header-height)*-1)] pt-[calc(var(--header-height)+3rem)]':
              props.sectionIndex === 0,
            'pt-30': props.sectionIndex !== 0,
          },
        )}
      >
        <div className="mx-auto max-w-[735px]">
          <div className="product-title mb-20 flex items-center justify-center gap-x-10 m:gap-x-15">
            <Media
              className="block h-35 w-35"
              mediaClassName="object-contain"
              media={props.productIcon}
              sizes="35px"
            />
            <h1 className="text-16 font-[440] leading-100 -tracking-1 m:text-22">
              {props.productTitle}
            </h1>
          </div>
          <Headline variant={2} value={props.headline} />
          {props.bodyCopy ? (
            <p className="text-body mx-auto mt-12 max-w-[480px] m:mt-30">
              {props.bodyCopy}
            </p>
          ) : null}
          {props.button.href ? (
            <Button {...props.button} className="mt-25 m:mt-30">
              {props.button.title}
            </Button>
          ) : null}
        </div>
        {props.media?.url ? (
          <div className="media mx-auto mt-30 max-w-1115 m:mt-45">
            <Media
              className="block w-full"
              media={props.media}
              sizes={['100vw', { '1165px': '1115px' }]}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
