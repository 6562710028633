import { useEffect, useRef, useState } from 'react';
import { useRect } from '@reach/rect';

import type { SanityTwoUpScroller } from '@/lib/sanity/queries/sections';
import { useAppSizes } from '@/lib/use-app-sizes';
import { cn } from '@/lib/utils';

import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';

export function TwoUpScroller(props: SanityTwoUpScroller) {
  const { windowWidth, windowHeight, headerHeight } = useAppSizes();
  const height = windowHeight - headerHeight;
  const mediaRef = useRef<HTMLDivElement>(null);
  const mediaRect = useRect(mediaRef);
  const [index, setIndex] = useState(0);

  return (
    <div className="p-main">
      <div className="mx-auto w-full max-w-1235">
        {props.headline ? (
          <div className="mx-auto mb-30 w-full max-w-480 text-left m:mb-0 m:max-w-735 m:text-center">
            <Headline
              variant={3}
              value={props.headline}
              overline={props.overline}
            />
          </div>
        ) : null}
        <div className="flex items-start">
          <div className="w-full m:w-1/2 m:pr-40 l:pr-60">
            <div className="mx-auto w-full max-w-480 space-y-50 m:max-w-360 m:space-y-0">
              {props.sections.map((section, i) => (
                <Content
                  key={section._key}
                  index={i}
                  section={section}
                  mediaRect={mediaRect}
                  height={height}
                  windowWidth={windowWidth}
                  onActive={setIndex}
                />
              ))}
            </div>
          </div>
          <div
            className="sticky hidden w-1/2 items-center m:flex"
            ref={mediaRef}
            style={{
              top: headerHeight,
              height,
            }}
          >
            {props.sections.map((section, i) => {
              return (
                <div
                  key={section._key}
                  style={{ zIndex: i + 1 }}
                  className={cn(
                    'absolute inset-0 size-full py-30 transition-opacity l:py-60',
                    {
                      'opacity-0': i > index,
                    },
                  )}
                >
                  <Media
                    media={section.media}
                    className="size-full"
                    mediaClassName="object-contain"
                    aspect={false}
                    sizes={['100vw', { m: '50vw' }]}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function Content({
  section,
  mediaRect,
  index,
  height,
  windowWidth,
  onActive,
}: {
  section: SanityTwoUpScroller['sections'][number];
  mediaRect: DOMRect | null;
  index: number;
  height: number;
  windowWidth: number;
  onActive: (index: number) => void;
}) {
  const threshold = 0.66;
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);
  const isMobile = windowWidth < 768;
  const margin =
    mediaRect && rect
      ? Math.max(100, Math.round((mediaRect.height - rect.height) / 2))
      : 0;
  const marginTop = isMobile ? 0 : margin;
  const marginBottom = isMobile ? 50 : margin;
  const midMediaTop = mediaRect
    ? mediaRect.top + mediaRect.height * threshold
    : 0;
  const midMediaBottom = mediaRect
    ? height - mediaRect.bottom + mediaRect.height * (1 - threshold)
    : 0;
  const isActive =
    isMobile ||
    (rect &&
      rect.top <= midMediaTop &&
      rect.top > height - (rect.height + midMediaBottom + margin));

  useEffect(() => {
    if (isActive) {
      onActive(index);
    }
  }, [isActive]);

  return (
    <div
      ref={ref}
      style={{ marginTop, marginBottom }}
      className={cn('relative transition', {
        'opacity-35': !isActive,
      })}
    >
      <Media media={section.media} className="mb-20 m:hidden" sizes="100vw" />
      <Headline
        value={section.headline}
        overline={section.overline}
        className="mb-25"
        variant={5}
      />
      <p className="text-body text-pretty">{section.bodyCopy}</p>
      {section.link && (
        <Button
          variant="link"
          className="mt-25"
          href={section.link.href}
          target={section.link.target}
        >
          {section.link.title}
        </Button>
      )}
    </div>
  );
}

{
  /* <div key={section._key} className="m:max-w-[360px]">
  <Media media={section.media} className="mb-20 m:hidden" />
  <Headline
    value={section.headline}
    overline={section.overline}
    className="mb-25"
    variant={5}
  />
  <p className="text-body">{section.bodyCopy}</p>
  {section.link && (
    <Button
      variant="link"
      className="mt-25"
      href={section.link.href}
      target={section.link.target}
    >
      {section.link.title}
    </Button>
  )}
</div> */
}

{
  /* <Media
  media={section.media}
  className="size-full"
  mediaClassName="object-contain object-top"
  sizes={['100vw', { m: '50vw' }]}
/> */
}
